/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';

import { BuilderComponent, builder } from "@builder.io/react";

// Styles
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Home.module.less';

// Components
import IntBanner from './parts/IntBanner/IntBannerLazy';
import CountryAbbr from '../../../Constants/CountryAbbr';
import {
  locationSelector,
  isIntBannerShown,
} from '../../../store/common/selectors';
import { setIntBannerShown } from '../../../store/common';

import Spinner from '../../../components/Spinner/Spinner';

import config from "../../../config";

// Builder Components
// theme heading component
import '../BuilderComponents/core/themeHeading/themeHeading.builder';

// theme paragraph component
import '../BuilderComponents/core/themeParagraph/themeParagraph.builder';

// theme button component
import '../BuilderComponents/core/themeButton/themeButton.builder';

// theme button container component
import '../BuilderComponents/container/themeButtonContainer/themeButtonContainer.builder';

// theme container component
import '../BuilderComponents/container/themeContainer/themeContainer.builder';

// theme blog card component
import '../BuilderComponents/core/themeBlogCard/themeBlogCard.builder';

// theme team card component
import '../BuilderComponents/core/themeTeamCard/themeTeamCard.builder';

// theme product card component
import '../BuilderComponents/core/themeProductCard/themeProductCard.builder';

// theme slider component
import '../BuilderComponents/container/themeSlider/themeSlider.builder';

// theme card component
import '../BuilderComponents/core/themeCardNew/themeCardNew.builder';

// theme step card component
import '../BuilderComponents/core/themeStepCard/themeStepCard.builder';

// theme hero component
import '../BuilderComponents/core/themeHero/themeHero.builder';

// theme scrolling section component

import '../BuilderComponents/container/themeScrollSection/themeScrollSection.builder';

// theme accordion component

import '../BuilderComponents/core/themeAccordion/themeAccordion.builder';

// theme LightBox component

import '../BuilderComponents/core/themeLightBox/themeLightBox.builder';

// theme Health Span Category component

import '../BuilderComponents/core/themeHealthSpanSection/themeHealthSpanSection.builder';

// theme How It Works component

import '../BuilderComponents/core/themeHowItWorks/themeHowItWorks.builder';

const BUILDER_API_KEY = config.builderIOAPIKey;
builder.init(BUILDER_API_KEY);

const HomePage = () => {
  const [lpSections, setLpSections] = useState(null);
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const locationData = useSelector(locationSelector);
  const isUsOrCaUser = !locationData?.countryCode || [CountryAbbr.US, CountryAbbr.CA].includes(locationData?.countryCode);
  const [showIntBanner, setShowIntBanner] = useState(!isUsOrCaUser);
  const intBannerShown = useSelector(isIntBannerShown);
  const dispatch = useDispatch();
  const showInternationalBanner = () => {
    setShowIntBanner(true);
  };

  const hideInternationalBanner = () => {
    setShowIntBanner(false);
    dispatch(setIntBannerShown());
  };

  React.useEffect(() => {
    if (!isUsOrCaUser) {
      showInternationalBanner();
    }
  }, [locationData]);

  React.useEffect(() => {
    builder
      .get("it-landing-page", {
        userAttributes: {
          // To allow targeting different announcements at different pages (URLs)
          urlPath: window.location.pathname,
        },
      })
      .toPromise()
      .then((lpSections) => {
        setLpSections(lpSections);
        setLoading(false);
      });
  }, []);
  if (loading) return <Spinner show/>;
  return (
    <>
      <Helmet>
        <title>
          {lpSections?.data?.pageSeoTitle || 'Optimize wellness and performance through blood tests, nutrition and science.'}
        </title>
        <meta
          name="description"
          content="Accurate blood biomarker analysis plus personalized interventions for nutrition, exercise and supplements to optimize wellness and fitness."
        />
      </Helmet>
      <div className={`${styles.page} ${styles.Home}`}>
        {showIntBanner && !intBannerShown
        && (
          <IntBanner
            onClickClose={hideInternationalBanner}
            classes={styles.intBanner}
          />
        )}
        { lpSections && (<BuilderComponent model="it-landing-page" content={lpSections} />)}
      </div>
    </>
  );
};
export default HomePage;
